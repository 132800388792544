.btn-active,.btn-active:hover  {
    background: #2a312c;
    color: #fff;
}
p{margin:0}
.react-datepicker-wrapper input {
    padding: 6px;
    border: 1px solid #dcdcdc;
    border-radius: 5px;
    width: 100%;
}