@media (max-width: 767px) {
    .mobile {
        display:block!important
    }

    .desktop {
        display: none!important
    }

    .menu-mobile-hide {
        display: none
    }

    .aside-menu {
        background: #133800d1!important;
        width: 100%;
    }

    #m-menu-close {
        position: absolute;
        top: 0;
        right: 86px;
        color: red;
        z-index: 2;
        background: #a1a1a1;
        padding: 1px
    }

    .aside-menu .menu-container {
        width: calc(100% - 84px);
        background: #008018;
        height: 100%;
    }

    main {
        width: 100%
    }
}
