
@media (min-width: 768px) { 
  .mobile {
    display: none!important;
  }
  .desktop {
    display: block!important;
  }
  main {
    width: calc(100% - 220px);    
  }
  .desktop-hide {
    display: none;
  }
  .aside-menu {
    width: 220px;
  }

}
