@import "mobile.css";@import "desktop.css";

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 700;
    line-height: 1.1;
    color: inherit
}

.h5,h5 {
    font-size: 1rem
}
.pointer {
  cursor: pointer;
}
a {
    display: inline-block;
    text-decoration: none;
    color: #fff
}

::-webkit-scrollbar {
    width: 12px;
    height: 12px
}

i,span {
    display: inline-block
}

::-webkit-scrollbar-track {
    border: 1px solid #6b6b6b;
    border-radius: 10px
}

::-webkit-scrollbar-thumb {
    background: #8c8c8c;
    border-radius: 10px
}

::-webkit-scrollbar-thumb:hover {
    background: #bcbcbc
}

body {
    font-family: Roboto,sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff
}
.title-modal {  
    box-shadow: 1px -1px 1px 1px #0b0b0b;
    background: #eaf2ec;

}
.aside-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    text-align: left;
    background: #008018;
    color: #fff;
    z-index: 999;
    overflow-y: auto;
    -webkit-transition: all .3s;
    transition: all .3s
}

.aside-menu .menu-container {
    position: relative;
    direction: ltr
}

.aside-menu .sidenav-header {
    background: #00723d;
    padding: 14px
}

.aside-menu .sidenav-header img {
    width: 50px;
    height: 50px
}

.aside-menu .sidenav-header h2 {
    margin: 10px 0 0;
    text-transform: uppercase;
    letter-spacing: .1em
}

.aside-menu .sidenav-header span {
    font-size: .8em;
    color: #fff
}

.aside-menu span {
    display: inline-block
}

.aside-menu .sidenav-heading {
    font-weight: 700;
    color: #fff;
    font-size: .9rem;
    text-transform: uppercase;
    letter-spacing: .1em;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    background: #14613d;
    margin: 0
}

.aside-menu li {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #009128
}

.aside-menu i {
    font-size: inherit;
    margin-right: 10px
}

.aside-menu li a {
    position: relative;
    font-size: .9rem;
    font-weight: 300;
    color: #fff;
    text-decoration: none;
    padding: 12px;
    display: block
}

.aside-menu li a:focus,.aside-menu li a:hover,.aside-menu li a[aria-expanded=true] {
    background: #33b35a;
    color: #fff;
    text-decoration: none
}

.aside-menu li a[data-toggle=collapse]:before {
    content: "keyboard_arrow_left";
    display: inline-block;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    font-family: Material Icons;
    position: absolute;
    top: 50%;
    right: 20px
}

.aside-menu li a[aria-expanded=true]:before {
    content: "keyboard_arrow_down";
    font-family: Material Icons;
    color: #fff
}

.aside-menu li ul a {
    padding-left: 25px;
    font-size: .85rem
}

.aside-menu li ul.collapse a {
    border-left: 2px solid #33b35a
}

.aside-menu .badge {
    display: inline-block;
    font-size: .6em;
    vertical-align: middle
}

.badge {
    font-weight: 400;
    border-radius: 0
}

.badge.badge-primary {
    background: #33b35a
}

.badge-success[href]:focus,.badge-success[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1e7e34
}

.badge-info {
    color: #fff;
    background-color: #17a2b8
}

.badge-info[href]:focus,.badge-info[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #117a8b
}

.badge-warning {
    color: #212529;
    background-color: #ffc107
}

.badge-warning[href]:focus,.badge-warning[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #d39e00
}

.badge-danger {
    color: #fff;
    background-color: #dc3545
}

.badge-danger[href]:focus,.badge-danger[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #bd2130
}

.badge-light {
    color: #212529;
    background-color: #f8f9fa
}

.badge-light[href]:focus,.badge-light[href]:hover {
    color: #212529;
    text-decoration: none;
    background-color: #dae0e5
}

.badge-dark {
    color: #fff;
    background-color: #343a40
}

.badge-dark[href]:focus,.badge-dark[href]:hover {
    color: #fff;
    text-decoration: none;
    background-color: #1d2124
}

.nav-link.disabled {
    color: #868e96
}

nav.navbar {
    background: #008018;
    padding: .8rem 0
}

nav.navbar .nav-menu {
    margin-bottom: 0
}

nav.navbar .container-fluid {
    width: 100%
}

nav.navbar a.menu-btn {
    width: 40px;
    height: 40px;
    line-height: 40px;
    /* background: #2f7b08; */
    color: #fff;
    text-align: center;
    padding: 7px;
}

nav.navbar .navbar-holder {
    width: 100%
}

nav.navbar a {
    font-size: .8rem;
    color: #fff;
    position: relative
}

nav.navbar a.navbar-brand {
    margin-left: 10px
}

nav.navbar .brand-text {
    text-transform: uppercase
}

.nav-link {
    padding: .5rem 1rem
}

nav.navbar .brand-text strong {
    font-size: 1.5em
}

nav.navbar .badge {
    position: absolute;
    top: 0;
    right: 0
}

nav.navbar a.language span {
    margin-left: .2rem;
    vertical-align: middle
}

nav.navbar a.logout i {
    margin-left: 5px
}

.nav-link i {
    font-size: 1.5em
}

main {
    position: absolute;
    top: 0;
    right: 0;
    -webkit-transition: width .3s linear;
    transition: width .3s linear;
    background-color: #f4f7fa;
    min-height: 100vh;
    padding-bottom: 50px
}

.breadcrumb-holder {
    background: #e3e7df;
    position: sticky;
    top: 0;
    z-index: 10;
}

.breadcrumb-holder .breadcrumb {
    padding: 15px 10px;
    font-size: 1.3rem;
    font-weight: 600;
    color: #404040;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}

a.btn.material-icons {
    background: #3266b7;
    padding: 5px;
    color: #fff
}

a.btn.material-icons[data-action=delete] {
    background: #8a1212
}

a.btn.material-icons:hover {
    background: #404040;
    color: #fff
}

ul#side-main-menu a.active {
    background: #33b35a
}

.bg-p1 {
    background: #7a7a7a;
    color: #fff;
    padding: 5px 15px;
    font-size: 1.2em;
    align-items: center
}

.form-floating-sm>.form-control,.form-floating-sm>.form-select {
    height: calc(2.5rem + 2px);
    line-height: 1.25;
    padding: .7rem .35rem
}

.form-floating-sm>label {
    padding: .5rem
}

tr[data-id][data-active=true] {
    background: #e1e1e1
}

tr.active {
    background: #464646;
    color: #fff
}

tr[data-id] {
    cursor: pointer
}

.item-img {
    position: relative;
    padding: 5px
}

.item-img:hover span.action-item {
    display: block
}

.item-img span.action-item {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #ff0808;
    color: #fff;
    font-size: 15px;
    display: none;
    cursor: pointer
}
a.nav-link:hover {
    background: #0e4d03;
    color: #fff;
}
.modal-header.p-2 {
    background: #008018;
    color: #fff;
}
.input-group > .form-floating {
  flex: 1 1 auto;
}
.input-group > .form-floating > .form-control {
    border-top-right-radius: unset;
    border-bottom-right-radius: unset;
}
.bg-edit {
  background: #008018;
    color: #fff;
  padding:5px;
}
.bg-close {
    background: #ff1212;
    color: #fff;
    padding: 5px;
}
.list-khoa {
    position: relative;
}
ul.ul-khoa {
    list-style: none;
    margin: 0;
    padding: 15px;
    display: flex;
    flex-direction: column;    
    box-shadow: 0px 0px 2px 1px #3e4d5d;
    background: #004d29;
      position: absolute;
    z-index: 10;
}

ul.ul-khoa li {
    margin: 0;
    padding: 0;
    text-align: left;
}
ul#menu-my-list-app {
    background: #044f12;
    z-index: 999;
    color: #fff;
    list-style: none;
    max-width: 500px;
    width: 100%!important;
    padding: 0;
    margin: 0;
}

ul#menu-my-list-app li {
    border-bottom: 1px solid #828282;
    padding: 5px;
}
.cursor-poiter {cursor: pointer;}
.sub-body {
    margin: 10px;
    background: #fff;
    padding: 15px 10px;
    box-shadow: -1px 1px 5px 2px #a9a9a7;
}
span[data-action], a[data-action] {
    cursor: pointer;
}

.list-images {
    display: flex;
    flex-wrap: wrap;
}

.content-img {
    max-width: 200px;
    min-width: 160px;
    /* width: 100%; */
    position: relative;
    background: #e3e3e3;
    margin: 5px;
    box-shadow: 0px 0px 3px 2px #b9b9b9;
    text-align: center;
    vertical-align: middle;
    display: flex;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}
.content-img:hover .overlay {
     display:block;
}
.content-img .overlay button[data-action="xoa"] {
    background:red

}
.content-img .overlay {
   display:none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
}

.content-img .overlay button {
    background: #5a9003;
    color: #fff;
    border: none;
    padding: 5px;
    box-shadow: -1px 2px 3px 1px #495867;
    margin-left: 5px;
    min-width:60px;
}
.content-img img {
    max-width: 200px;
    min-width: 160px;
    text-align: center;
    vertical-align: middle;
}

.content-img span {
    text-align: center;
   
    top: 10px;
    width: 100%;
    background: #14171961;
    color: #fff;
}
.hinh-anh {
    position: relative;
}


.hinh-anh label.btn-hinh-anh {
    position: absolute;
    right: 10px;
    top: 0.9rem;   
    z-index: 2;
    padding: 5px;
}
table#danh-sach td img {
    max-width: 150px;
    max-height: 60px;
}
.list-imgs {
    display: flex;  
    flex-wrap: wrap;
  
}
#store-images {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top:0;
}

.item-img img {
    max-width: 100%;
    height: auto;
}

.item-img {
    max-width: 99px;
    margin: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    background: #ececec;
}
.item-img .v-close {
    position: absolute;
    top: 0;
    right: 0;
    color: #fff;
    background: #123;
    display: none;
  cursor: pointer;
}

.item-img:hover span {
    display: block;
}